import {
  Card,
  CardInfo,
  Container,
  Creator,
  Image,
  InfoBottom,
  InfoTop,
  Title,
} from "./styles";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { BtnPrimaryLg } from "../Buttons/styles";
import { redirectTo } from "../../pages/home/functions";
import XmlToJson from "../XlsReader";
import { useEffect, useState } from "react";

interface Props {
  setImageLink: React.Dispatch<React.SetStateAction<string>>;
  setVisibility: React.Dispatch<React.SetStateAction<string>>;
  curso_xls?: string;
}
const formatLink = (name: string) => {
  return `https://wa.me/5511980982793?text=Olá, tenho interesse no Curso ${name.replace(
    "Curso ",
    ""
  )}`;
};

const groupItems = (array: any[], groupSize: number) => {
  const groupped = [];
  for (let i = 0; i < array.length; i += groupSize) {
    groupped.push(array.slice(i, i + groupSize));
  }

  return groupped;
};

export const CarouselXls: React.FC<Props> = (props: Props) => {
  const [jsonCursos, setJsonCursos] = useState<any[]>([]);
  const [cardGroups, setCardGroups] = useState<any[]>([]);

  const pago =
    props.curso_xls && props.curso_xls.includes("pagos") ? true : false;
  console.log(props.curso_xls);

  useEffect(() => {
    XmlToJson(
      props.curso_xls ? props.curso_xls : "/tabela_de_preços_cursos_pagos.xlsx"
    ).then((data) => {
      setJsonCursos(data);
    });
  }, [props.curso_xls]);

  useEffect(() => {
    if (jsonCursos.length > 0) {
      setCardGroups(groupItems(jsonCursos, 3));
    }
  }, [jsonCursos]);
  return (
    <Container>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="carouselMain mobileCarousel"
      >
        {jsonCursos.map((card, idx) => (
          <SwiperSlide key={idx}>
            <Card>
              <Image
                link={`"/images/cursos/${card[
                  Object.keys(card)[0]
                ].trim()}.png?20240523"`}
                onClick={() => {
                  props.setImageLink(
                    `/images/cursos/${card[
                      Object.keys(card)[0]
                    ].trim()}.png?20240523`
                  );
                  props.setVisibility("visible");
                }}
              />
              <CardInfo>
                <InfoTop className={pago ? "paid-top" : ""}>
                  <Title>{card[Object.keys(card)[0]]}</Title>
                  <Creator>
                    Criado por &nbsp;
                    <span className="highlight">Mestra Chris</span>
                  </Creator>
                </InfoTop>
                <InfoBottom className={pago ? "paid-bottom" : ""}>
                  <BtnPrimaryLg
                    onClick={() =>
                      redirectTo(formatLink(card[Object.keys(card)[0]]))
                    }
                  >
                    Saiba mais
                  </BtnPrimaryLg>
                </InfoBottom>
              </CardInfo>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      {cardGroups.length > 0 && (
        <Swiper
          navigation={true}
          pagination={true}
          modules={[Navigation, Pagination]}
          className="carouselMain"
        >
          {cardGroups.map((group, idx) => (
            <SwiperSlide key={idx}>
              {group.map((card: Record<string, any>, index: number) => (
                <Card key={index}>
                  <Image
                    link={`"/images/cursos/${card[
                      Object.keys(card)[0]
                    ].trim()}.png?20240523"`}
                    onClick={() => {
                      props.setImageLink(
                        `/images/cursos/${card[
                          Object.keys(card)[0]
                        ].trim()}.png?20240523`
                      );
                      props.setVisibility("visible");
                    }}
                  />
                  <CardInfo>
                    <InfoTop className={pago ? "paid-top" : ""}>
                      <Title>{card[Object.keys(card)[0]]}</Title>
                      <Creator>
                        Criado por &nbsp;
                        <span className="highlight">Mestra Chris</span>
                      </Creator>
                    </InfoTop>
                    <InfoBottom className={pago ? "paid-bottom" : ""}>
                      {pago && <>R$ {card[Object.keys(card)[1]]}</>}
                      <BtnPrimaryLg
                        onClick={() =>
                          redirectTo(formatLink(card[Object.keys(card)[0]]))
                        }
                      >
                        Saiba mais
                      </BtnPrimaryLg>
                    </InfoBottom>
                  </CardInfo>
                </Card>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Container>
  );
};

export default CarouselXls;
